import $ from 'jquery';
import debug from 'bows';

const log = debug('centrus:MegaMenu');

class MegaMenu {
	constructor(options = {
		container: $('.Nav--primary')[0],
		firstLevelContainerSelector: '.Nav-menuItem--depth0',
		secondLevelContainerSelector: '.Nav-submenu',
		firstLevelLinkSelector: '.Nav-link',
		activeClass: 'is-active',
		timeoutDelay: 333,
		// Currently hardcoded below
		mobileScreenWidth: 800
	}) {
		// constants
		this.ACTIVE_CLASS = options.activeClass;
		this.FIRST_LEVEL_CONTAINER_SELECTOR = options.firstLevelContainerSelector;
		this.SECOND_LEVEL_CONTAINER_SELECTOR = options.secondLevelContainerSelector;
		this.FIRST_LEVEL_LINK_SELECTOR = options.firstLevelLinkSelector;
		this.TIMEOUT_DELAY = options.timeoutDelay;
		this.MOBILE_SCREEN_WIDTH = options.mobileScreenWidth;
		this.$container = $(options.container);
		this.interval = null;
		this.$active = null;
		this.init();
		this.mobileNav();
	}

	init() {
		log('init');

		this.$container.on('focus', this.FIRST_LEVEL_CONTAINER_SELECTOR, (e) => {
			log('focus', e);

			// const $target = $(e.target);

			const $item = $(e.currentTarget);
			const $link = $item.find(this.FIRST_LEVEL_LINK_SELECTOR);

			if ($link.attr('aria-haspopup')) {
				this.setActive($item);
			}

			this.clearInterval();
		});

		this.$container.on('mouseenter', this.FIRST_LEVEL_CONTAINER_SELECTOR, (e) => {
			log('mouseenter', e);
			const $item = $(e.currentTarget);
			this.setActive($item);

			this.clearInterval();
		});

		this.$container.on('mouseleave', this.FIRST_LEVEL_CONTAINER_SELECTOR, (e) => {
			log('mouseleave', e);
			const $item = $(e.currentTarget);

			// skip: Focus is inside menuItem
			if (document.activeElement && $item.has(document.activeElement).length) {
				return;
			}

			this.clearInterval();
			this.setInterval(() => this.removeActive($item));
		});

		this.$container.on('blur', this.FIRST_LEVEL_CONTAINER_SELECTOR, (e) => {
			log('blur', e.relatedTarget);
			const $item = $(e.currentTarget);

			// skip: we focused a different element inside menuItem
			if (e.relatedTarget && $item.has(e.relatedTarget).length) {
				return;
			}

			clearInterval(this.interval);
			this.setInterval(() => this.removeActive($item));
		});
	}

	setActive($item) {
		log('setActive', $item);

		if ($(window).width() < 800) {
			return false;
		}

		// toggle previous active element
		if (this.$active && $item[0] !== this.$active[0]) {
			this.removeActive();
		}

		this.toggleAria($item, true);
		$item.addClass(this.ACTIVE_CLASS);
		this.$active = $item;
	}

	removeActive($item = this.$active) {
		log('removeActive', $item);
		this.toggleAria($item, false);
		$item.removeClass(this.ACTIVE_CLASS);
		this.$active = null;
	}

	toggleAria($item, bool) {
		$item.find(`${this.FIRST_LEVEL_LINK_SELECTOR}, ${this.SECOND_LEVEL_CONTAINER_SELECTOR}`).attr('aria-expanded', bool);
	}

	clearInterval() {
		clearInterval(this.interval);
	}

	setInterval(cb = function () { }) {
		this.interval = setTimeout(() => cb(), this.TIMEOUT_DELAY);
	}
	mobileNav() {
		var $mobileMenuButton = $('.MobileMenu');
		var $HeaderNav = $('.Header-nav');
		var navType = ($(window).width() >= 800) ? 'desktop' : 'mobile';


		// Prevent deactivation when clicking content in the menu
		$HeaderNav.on('click touchend', function (e) {
			if (navType === 'mobile' && $HeaderNav.hasClass('active')) {
				e.stopPropagation();
			}
		});

		// Activate the menu through the mobile menu button
		$mobileMenuButton.on('click touchend', function (e) {
			if (navType === 'mobile' && !$HeaderNav.hasClass('active')) {
				$(window).trigger('centrusActivate.menu');
				e.preventDefault();
				e.stopPropagation();
			}
		});

		$('.MobileSearchButton').on('click touchend', function (e) {
			if (navType === 'mobile' && !$HeaderNav.hasClass('active')) {
				$(window).trigger('centrusActivate.menu');
				$('.Header-nav .SearchForm .SearchForm-input').focus();
				e.preventDefault();
				e.stopPropagation();
			}
		});

		// Click off the menu, hide it
		$(document).on('click touchend', function (e) {
			if (navType === 'mobile' && $(this).parents('.Header-nav') && $HeaderNav.hasClass('active')) {
				$(window).trigger('centrusDeactivate.menu');
				e.preventDefault();
				e.stopPropagation();
			}
		});

		// Primary nav interaction
		$('.Nav-menuItemContainer > .Nav-link').on('click touchend', function (e) {
			if (navType === 'mobile' && $HeaderNav.hasClass('active')) {
				e.preventDefault();
				e.stopPropagation();
				var $submenu = $(this).parents('.Nav-menuItem--depth0');
				if ($submenu.hasClass('active')) {
					$submenu.removeClass('active');
				} else {
					$submenu.addClass('active');
				}
			}
		});
		// Secondary nav interaction
		$('.Nav-submenuLink--depth1').on('click touchend', function (e) {
			if (navType === 'mobile' && !$(this).parent().hasClass('active')) {
				e.preventDefault();
				e.stopPropagation();
				var $submenu = $(this).parent();
				if ($submenu.hasClass('active')) {
					$submenu.removeClass('active');
				} else {
					$submenu.addClass('active');
				}
			}
		});

		$(window).on('centrusActivate.menu', function (e) {
			$HeaderNav.addClass('active');
			$('.Mobile-nav').css('display', 'none');

			$('.Nav-link').each(function (i, val) {
				var $this = $(this);
				var newContent = 'Overview of ' + $this.text();
				var $link = $this.clone().text(newContent);
				$this.parents('.Nav-menuItem--depth0').find('.Nav-submenuWrap').prepend($link);
			});
			$('.Header-nav .SearchForm').insertBefore('.Header-nav .Nav--primary');
			$('.Header-nav .Nav--utility').insertAfter('.Nav--primary');
		});

		$(window).on('centrusDeactivate.menu', function (e) {
			$('.Mobile-nav').css('display', '');
			$HeaderNav.removeClass('active').find('li').removeClass('active');

			$('.Nav-submenuWrap .Nav-link').remove();

			$('.Header-nav .SearchForm').insertAfter('.Header-nav .Nav--primary');
			$('.Header-nav .Nav--utility').insertBefore('.Nav--primary');
		});

		$(window).on('resize', $.debounce(50, function () {
			var testNav = navType;
			navType = ($(window).width() >= 800) ? 'desktop' : 'mobile';
			if (navType !== testNav) {
				if (navType === 'desktop') {
					$(window).trigger('centrusDeactivate.menu');
				}
			}
		}));
	}
}

export default MegaMenu;
