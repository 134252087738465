/* globals FWP */

import $ from 'jquery';
import debug from 'bows';

const log = debug('centrus:FacetsWP');
const $body = $(document.body);
const LOADING_CLASS = 'is-facetWPLoading';
const ACTIVE_CLASS = 'is-facetWPEnabled';

class LayoutTopContent {
	constructor(options = {}) {
		this.defaults = {
			container: null,
			resetSelector: '.js-reset'
		};

		options = $.extend({}, this.defaults, options);
		this.options = options;

		log('options', options);

		this.container = options.container;
		this.$container = $(options.container);

		this.init();
	}

	init() {
		if (!this.$container.length) {
			log('init:skipped');
			return;
		}

		log('init:start');

		const $document = $(document);

		this.$container.on('click', this.options.resetSelector, (e) => {
			e.preventDefault();
			this.$container.find('.facetwp-checkbox.checked').removeClass('checked');
			FWP.refresh();
		});

		$document.on('facetwp-refresh', (e) => {
			$body.addClass(LOADING_CLASS);
		});

		$document.on('facetwp-loaded', (e) => {
			log('facetwp-loaded!');
			$body.removeClass(LOADING_CLASS);

			if (FWP.facets.categories.length || FWP.facets.year.length) {
				$body.addClass(ACTIVE_CLASS);
			} else {
				$body.removeClass(ACTIVE_CLASS);
			}

			const $dropdown = this.$container.find('.facetwp-dropdown');
			const $checkboxes = this.$container.find('.facetwp-checkbox');
			const checkedClassName = $checkboxes.filter('.checked').length < 1 ? 'checked' : '';

			$checkboxes.attr('tabindex', 0);
			if (!this.$container.find('.Facets-categoriesReset').length) {
				this.$container.find('.facetwp-facet-categories').prepend(`<a href="#" class="Facets-categoriesReset js-reset ${checkedClassName}">All</a>`);
			}
			$dropdown.select2({
				minimumResultsForSearch: Infinity
			});
		});

		log('init:end');
	}
}

export default LayoutTopContent;
