import $ from 'jquery';
import debug from 'bows';

const log = debug('centrus:Header');

class Header {
	constructor(options = {}) {
		this.defaults = {
			container: $('.Header')[0],
			headerActiveClass: 'is-small',
			bodyFixedClass: 'is-headerFixed',
			offset: 30,
			// Currently hardcoded below
			mobileScreenWidth: 800
		};

		options = $.extend({}, this.defaults, options);
		this.options = options;
		log(options);

		this.state = '';
		this.container = this.options.container;
		this.$container = $(this.container);

		this.init();
		this.searchBox();
	}

	init() {
		log('init');
		const $window = $(window);

		$window.on('scroll.header', (e) => {
			const offset = this.options.offset;
			const currentState = this.state;
			const currentOffset = window.pageYOffset;

			if (currentState !== 'big' && currentOffset < offset) {
				return this.makeItBig();
			}

			if (currentState !== 'small' && currentOffset >= offset) {
				return this.makeItSmall();
			}
		});

		$window.trigger('scroll.header');
	}
	searchBox() {
		$('.Header-nav .SearchForm .SearchForm-input').focusin(function () {
			var ww = $(window).width();
			if (ww < 1000 && ww >= 800) {
				var $parent = $(this).parent();
				$parent.find('path').css('fill', '#000');
				$parent.find('button').addClass('active');
				$('.Header-nav .SearchForm').addClass('active');
			}
		});
		$('.Header-nav .SearchForm .SearchForm-input').focusout(function () {
			var ww = $(window).width();
			if (ww < 1000 && ww >= 800) {
				var $parent = $(this).parent();
				$parent.find('path').css('fill', '');
				$parent.find('button').removeClass('active');
				$('.Header-nav .SearchForm').removeClass('active');
			}
		});
		$(document).on('click touchend', function (e) {
			var ww = $(window).width();
			if (ww < 1000 && ww >= 800 && $('.Header-nav .SearchForm').hasClass('active') && !$(this).parents('.SearchForm')) {
				var $search = $('.Header-nav .SearchForm');
				$search.find('path').css('fill', '');
				$search.find('button').removeClass('active');
				$search.removeClass('active');
				e.stopPropagation();
			}
		});

		$('.Header-nav .SearchForm .SearchForm-icon').on('click', function () {
			// Cause search icon to submit form
			var ww = $(window).width();
			if (ww < 800) {
				$(this).parent().submit();
			}
		});

	}

	makeItBig() {
		log('make it bigger');
		this.state = 'big';
		this.$container.removeClass(this.options.headerActiveClass);
	}

	makeItSmall() {
		log('make it smaller');
		this.state = 'small';
		this.$container.addClass(this.options.headerActiveClass);
	}
}

export default Header;
