import $ from 'jquery';
import debug from 'bows';
import Flickity from 'flickity';

const log = debug('centrus:LayoutSlideshow');

class LayoutSlideshow {
	constructor(options = {}) {
		this.defaults = {
			container: null,
			slideshowContainer: null,
			cellSelector: '.LayoutSlideshow-slide',
			slideshowContainerSelector: '.LayoutSlideshow-slideshow',
			flickityOptions: {}
		};

		options = $.extend({}, this.defaults, options);
		this.options = options;
		log(options);

		this.container = options.container;
		this.$container = $(options.container);
		this.slideshowContainerSelector = options.slideshowContainerSelector;
		this.$slideshowContainer = this.$container.find(options.slideshowContainerSelector);
		this.slideshowContainer = this.$slideshowContainer[0];
		this.cellSelector = options.cellSelector;
		this.$slides = this.$container.find(options.cellSelector);
		this.slides = this.$slides.toArray();
		this.flickityOptions = options.flickityOptions;
		this.init();
	}

	init() {
		log(this.$container);
		if (!this.$slideshowContainer.length || this.$slides.length <= 1) {
			log('init:skipped', {
				$container: this.$container,
				$slideshowContainer: this.$slideshowContainer,
				$slides: this.$slides
			});
			return;
		}

		log('init:start', {
			container: this.container,
			slideshowContainer: this.slideshowContainer,
			cellSelector: this.cellSelector,
			slides: this.slides
		});

		this.flickity = new Flickity(this.slideshowContainer, {
			cellSelector: this.cellSelector,
			imagesLoaded: true,
			prevNextButtons: false
		}, this.flickityOptions);

		this.flickity.on('cellSelect', () => this.buttonsStates());

		this.buttonEvents();
		this.slideCount();
		log('init:end', {
			flickity: this.flickity
		});
	}

	slideCount() {
		$('.flickity-enabled').each(function () {
			const isFull = $(this).closest('.is-fullWidthSlides');
			if (isFull.length >= 1) {
				const slides = isFull.find('.LayoutSlideshow-slide');
				let i = 1;
				slides.each(function () {
					let printI = i;
					let printLength = slides.length;
					if (printI < 10) {
						printI = `0${printI}`;
					}

					if (printLength < 10) {
						printLength = `0${printLength}`;
					}
					let slideCount = `<div class="slidecount"><span class="slidecount__current">${printI}</span><span class="slidecount__total"> / ${printLength}</span></div>`;
					$(this).find('.LayoutSlideshow-slideContent').addClass('LayoutSlideshow-slideContent--count').append(slideCount);
					i++;
				})
			}
		});
	}

	buttonsStates() {
		const length = this.flickity.cells.length;
		const index = this.flickity.selectedIndex;
		const $prev = this.$container.find('.LayoutSlideshow-button.is-prev');
		const $next = this.$container.find('.LayoutSlideshow-button.is-next');

		$prev.toggleClass('is-disabled', index === 0);
		$next.toggleClass('is-disabled', index === (length - 1));

		// log('selectedIndex', this.flickity.selectedIndex);
		// log('cells', this.flickity.cells.length);
	}

	buttonEvents() {
		this.$container.on('click', '.LayoutSlideshow-button.is-prev:not([disabled])', (e) => {
			e.preventDefault();
			this.flickity.previous();
		});

		this.$container.on('click', '.LayoutSlideshow-button.is-next:not([disabled])', (e) => {
			e.preventDefault();
			this.flickity.next();
		});
	}
}

export default LayoutSlideshow;
