import $ from 'jquery';
import debug from 'bows';
import Flickity from 'flickity';

const log = debug('centrus:Slideshow');

class Slideshow {
	constructor(options = {}) {
		this.defaults = {
			container: null,
			slideshowContainer: null,
			cellSelector: '.Slideshow-slide',
			slideshowContainerSelector: '.Slideshow-slideshow',
			flickityOptions: {}
		};

		options = $.extend({}, this.defaults, options);
		this.options = options;
		log(options);

		this.container = options.container;
		this.$container = $(options.container);
		this.slideshowContainerSelector = options.slideshowContainerSelector;
		this.$slideshowContainer = this.$container.find(options.slideshowContainerSelector);
		this.slideshowContainer = this.$slideshowContainer[0];
		this.cellSelector = options.cellSelector;
		this.$slides = this.$container.find(options.cellSelector);
		this.slides = this.$slides.toArray();
		this.flickityOptions = options.flickityOptions;

		this.init();
	}

	init() {
		log(this.$container);
		if (!this.$slideshowContainer.length || this.$slides.length <= 1) {
			log('init:skipped', {
				$container: this.$container,
				$slideshowContainer: this.$slideshowContainer,
				$slides: this.$slides
			});
			return;
		}

		log('init:start', {
			container: this.container,
			slideshowContainer: this.slideshowContainer,
			cellSelector: this.cellSelector,
			slides: this.slides
		});

		const flickityOptions = $.extend({}, {
			cellSelector: this.cellSelector,
			imagesLoaded: true,
			prevNextButtons: false
		}, this.flickityOptions);

		this.flickity = new Flickity(this.slideshowContainer, flickityOptions);

		this.flickity.on('cellSelect', () => this.buttonsStates());

		this.buttonEvents();

		log('init:end', {
			flickity: this.flickity
		});
	}

	buttonsStates() {
		const length = this.flickity.cells.length;
		const index = this.flickity.selectedIndex;
		const $prev = this.$container.find('.Slideshow-button.is-prev');
		const $next = this.$container.find('.Slideshow-button.is-next');

		$prev.toggleClass('is-disabled', index === 0);
		$next.toggleClass('is-disabled', index === (length - 1));
	}

	buttonEvents() {
		log('buttonevents');
		this.$container.on('click', '.Slideshow-button.is-prev:not([disabled])', (e) => {
			log('prev');
			e.preventDefault();
			this.flickity.previous();
		});

		this.$container.on('click', '.Slideshow-button.is-next:not([disabled])', (e) => {
			log('next');
			e.preventDefault();
			this.flickity.next();
		});
	}
}

export default Slideshow;
