import $ from 'jquery';
import debug from 'bows';

const log = debug('centrus:TopContent');
const LOCALSTORAGE_INDEX = `centrus:topContent.currentItem`;

class LayoutTopContent {
	constructor(options = {}) {
		this.defaults = {
			container: null,
			items: []
		};

		log('options', options);

		this.container = options.container;
		this.$container = $(options.container);
		this.items = options.items;

		this.init();
	}

	init() {
		if (!this.$container.length || !this.items || !this.items.length) {
			log('init:skipped');
			return;
		}

		log('init:start');
		this.currentItem = localStorage.getItem(LOCALSTORAGE_INDEX);
		this.setRandomBackground();
		log('init:end');
	}

	setRandomBackground() {
		if (this.items.length > 1) {
			this.getRandom();
		} else {
			this.currentItem = this.items[0];
		}

		this.$container.css('background-image', `url(${this.currentItem})`);

		localStorage.setItem(LOCALSTORAGE_INDEX, this.currentItem);
	}

	getRandom() {
		this.items.sort(() => Math.round(Math.random()) - 0.5);

		const newItem = this.items[0];

		// if we got the same item twice in a row
		// get a different item as long as there is
		// more than 1 item available
		if (this.items.length > 1 && newItem === this.currentItem) {
			return this.getRandom();
		}

		this.currentItem = newItem;
		return newItem;
	}
}

export default LayoutTopContent;
