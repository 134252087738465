import $ from 'jquery';

// import Packery from 'packery';
// import imagesLoaded from 'imagesloaded';
// import magnificPopup from 'magnificpopup';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeDefault from 'photoswipe-ui-default';
import debug from 'bows';

const log = debug('centrus:LayoutGallery');

class LayoutGallery {
	constructor(options = {}) {
		this.defaults = {
			container: null,
			viewportWidth: 0,
			linksSelector: '.LayoutGallery-imageLink'
		};

		options = $.extend({}, this.defaults, options);
		log(options);

		this.options = options;
		this.container = options.container;
		this.$container = $(options.container);
		this.active = false;

		// this.bindEvents();
		this.init();
	}

	bindEvents() {
		if (this.options.viewportWidth <= 0) {
			this.init();
			return;
		}

		// TODO: add a debouncer
		$(window).on('resize.layout-gallery', (e) => {
			if (!this.active && $(window).width() >= this.options.viewportWidth) {
				this.init();
				return;
			}

			if (this.active && $(window).width() < this.options.viewportWidth) {
				this.$container.find(this.options.linksSelector).off('click.layout-gallery');
				this.$container.packery('destroy');
				this.active = false;
				return;
			}
		});

		$(window).trigger('resize.layout-gallery');
	}

	init() {
		log(this.$container);

		this.$container.packery();
		this.$container.imagesLoaded().progress(() => {
			this.$container.packery();
		});

		const photoSwipeDOM = $('.pswp')[0];
		const $thumbLinks = this.$container.find(this.options.linksSelector);

		const items = [];
		$thumbLinks.toArray().forEach((link) => {
			const $link = $(link);
			items.push({
				src: $link.attr('href'),
				w: $link.data('width'),
				h: $link.data('height'),
				title: $link.data('caption')
			});
		});

		const options = {
			index: 0,
			bgOpacity: 0.7,
			showHideOpacity: true,
			loop: false,
			closeOnScroll: false,
			history: false,
			galleryUID: new Date(),
			getThumbBoundsFn: (index) => {
				const thumb = $thumbLinks[index];
				const rect = thumb.getBoundingClientRect();
				const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
				return {
					x: rect.left,
					y: rect.top + pageYScroll,
					w: rect.width
				};
			}
		};

		$thumbLinks.on('click.layout-gallery', (e) => {
			e.preventDefault();

			const index = $(e.currentTarget).index();
			options.index = index;

			log('click', e);
			log('index', index);
			log('moar', {
				photoSwipeDOM: photoSwipeDOM,
				PhotoSwipeDefault: PhotoSwipeDefault,
				items: items,
				options: options
			});

			const gallery = new PhotoSwipe(photoSwipeDOM, PhotoSwipeDefault, items, options);
			gallery.init();
		});

		this.active = true;
	}
}

export default LayoutGallery;
