import $ from 'jquery';
import debug from 'bows';

const log = debug('centrus:Accordion');

class Accordion {
    constructor(options = {}) {

        this.acb = $('.accordion__button');

        this.init();
    }

    accordionButtons() {
        this.acb.on('click', function () {
            $(this).closest('.accordion').toggleClass('--open').find('.accordion__content').slideToggle();
        });
    }
    init() {
        log(this.acb.length);
        if (this.acb.length) {
            this.accordionButtons();
        }
    }



}

export default Accordion;
