import $ from 'jquery';
import debug from 'bows';
import Flickity from 'flickity';

const log = debug('centrus:FrontHistory');

class FrontHistory {
	constructor(options = {
		container: $('.FrontHistory')[0],
		slideshowContainer: $('.FrontHistory-slideshow')[0],
		cellSelector: '.FrontHistory-slide'
	}) {
		this.container = options.container;
		this.$container = $(options.container);
		this.slideshowContainer = options.slideshowContainer;
		this.$slideshowContainer = $(options.slideshowContainer);
		this.cellSelector = options.cellSelector;
		this.$slides = $(options.cellSelector);
		this.slides = this.$slides.toArray();
		this.init();
	}

	init() {
		if (!this.$slideshowContainer.length || this.$slides.length <= 1) {
			log('init:skipped', {
				$container: this.$container,
				$slideshowContainer: this.$slideshowContainer,
				$slides: this.$slides
			});
			return;
		}

		log('init:start', {
			container: this.container,
			slideshowContainer: this.slideshowContainer,
			cellSelector: this.cellSelector,
			slides: this.slides
		});

		this.flickity = new Flickity(this.slideshowContainer, {
			cellSelector: this.cellSelector,
			imagesLoaded: true,
			prevNextButtons: false,
			resize: false,
			setGallerySize: false
		});

		this.flickity.on('cellSelect', () => this.resize());
		this.flickity.on('cellSelect', () => this.buttonsStates());

		this.buttonEvents();
		$(window).on('resize', () => this.resize());

		log('init:end', {
			flickity: this.flickity
		});
	}

	resize() {
		const sizes = [];
		const height = $(this.flickity.selectedElement).outerHeight();
		this.$slides.each((i, el) => sizes.push($(el).outerHeight()));
		const tallestHeight = sizes.reduce((a, b) => (a > b) ? a : b);
		this.$slideshowContainer.css('height', tallestHeight);
		this.$container.find('.flickity-viewport').css({ height: height });

		// $(this.flickity.element).find('.flickity-slider').css({
		// 	height: height,
		// 	marginTop: -(height / 2)
		// });
		log('resize', height);
	}

	buttonsStates() {
		const length = this.flickity.cells.length;
		const index = this.flickity.selectedIndex;
		const $prev = this.$container.find('.FrontHistory-button.is-prev');
		const $next = this.$container.find('.FrontHistory-button.is-next');

		$prev.toggleClass('is-disabled', index === 0);
		$next.toggleClass('is-disabled', index === (length - 1));
	}

	buttonEvents() {
		this.$container.on('click', '.FrontHistory-button.is-prev:not([disabled])', (e) => {
			e.preventDefault();
			this.flickity.previous();
		});

		this.$container.on('click', '.FrontHistory-button.is-next:not([disabled])', (e) => {
			e.preventDefault();
			this.flickity.next();
		});
	}
}

export default FrontHistory;
