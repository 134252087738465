import $ from 'jquery';
import debug from 'debug';

import Header from './_header'
import MegaMenu from './_mega-menu';
import FacetsWP from './_facetswp';
import FrontHero from './_front-hero';
import FrontHistory from './_front-history';
import LayoutSlideshow from './_layout-slideshow';
import LayoutGallery from './_layout-gallery';
//import LayoutLeadershipMembers from './_layout-leadership-members';
import LayoutTopContent from './_layout-top-content';
import Slideshow from './_slideshow';
import Accordion from './_accordion';

const $window = $(window);
const $document = $(document);
const $body = $('body');

class Site {
	constructor() {
		const log = debug('centrus:constructor');
		log('start');

		$document.ready(() => this.domReady());
		$window.on('load', () => this.windowLoad());

		log('end');
	}

	domReady() {
		const log = debug('centrus:domReady');
		log('dom.ready');

		new Header();
		new MegaMenu();
		new Accordion();
		this.frontPage();
		this.frontPageNew();
		this.archivePages();
		this.leadershipPage();
		this.layouts();
		this.blurLinks();
		this.tinyNav();
	}

	windowLoad() {
		const log = debug('centrus:windowLoad');
		log('window.onload');
		this.emptyLinks();

		// this.externalLinks();
	}

	frontPage() {
		if (!$body.hasClass('home')) {
			return;
		}

		new FrontHero();
		new FrontHistory();
	}

	frontPageNew() {
		if (!$body.hasClass('page-template-front-page-new')) {
			return;
		}
		if ($body.hasClass('home') === true && $body.hasClass('page-template-front-page-new') === true) {
			return;
		} else if ($body.hasClass('page-template-front-page-new') === true) {
			new FrontHistory();
		}
	}



	archivePages() {
		if (!$('.Facets').length) {
			return;
		}

		$('.Facets').each((i, container) => new FacetsWP({
			container: $('.MainContainer')[0]
		}));
	}

	leadershipPage() {
		const $officersSlideshow = $('.OfficersSlideshow');
		if ($officersSlideshow.length) {
			const $buttons = $officersSlideshow.find('.Slideshow-button');
			const log = debug('centrus:Slideshow:OfficersSlideshow');
			$officersSlideshow.toArray().forEach((container) => {
				const slideshow = new Slideshow({
					container: container,
					flickityOptions: {
						pageDots: false
					}
				});

				$(container).on('cellSelect', (e) => {
					log('selected event');
					const $el = $(slideshow.flickity.selectedElement);
					$el.imagesLoaded().done((instance) => {
						log('loaded', instance);
						const height = $(instance.images[0].img).height();
						if (height > 1) {
							$buttons.css('top', height / 2);
						}
					});
				});
			});
		}
	}

	layouts() {
		if ($('.LayoutSlideshow').length) {
			$('.LayoutSlideshow').toArray().forEach((container) => {
				if ($(container).find('.LayoutSlideshow-slide').length < 2) {
					return;
				}

				new LayoutSlideshow({
					container: container
				});
			});
		}

		if ($('.LayoutGallery').length) {
			$('.LayoutGallery').toArray().forEach((container) => {
				const imagesContainer = $(container).find('.LayoutGallery-images')[0];
				new LayoutGallery({
					viewportWidth: 801,
					container: imagesContainer
				});
			});
		}

		// if ($('.LayoutLeadershipMembers').length) {
		// 	$('.LayoutLeadershipMembers').toArray().forEach((container) => {
		// 		new LayoutLeadershipMembers({
		// 			container: container
		// 		});
		// 	});
		// }

		if (window.ConfigLayoutTopContentBgImages && $('.LayoutTopContent').length) {
			const $container = $('.LayoutTopContent');
			new LayoutTopContent({
				container: $container[0],
				items: window.ConfigLayoutTopContentBgImages
			});
		}
	}

	blurLinks() {
		$document.on('click', 'a, button', function () {
			$(this).trigger('blur');
		});
	}

	emptyLinks() {
		const log = debug('centrus:emptyLinks');
		const links = $('a[href=""], a[href="#"]').toArray();
		log(`There are ${links.length} empty links on this page`, links);
	}

	externalLinks() {
		const log = debug('centrus:externalLinks');
		const $links = $('a[href]').filter((i, el) => {
			const href = $(el).attr('href').trim();
			const isEmpty = href === '';
			const isLocal = href.indexOf(document.location.origin) === 0;
			const isAnchor = href.indexOf('#') === 0;

			if (isEmpty || isLocal || isAnchor) {
				return false;
			}

			return true;
		});
		$links.attr('target', '_blank');
		log(`There are ${$links.length} external links on this page`, $links);
	}
	tinyNav() {
		$('#menu-investor-relations').tinyNav({
			header: 'Menu'
		});
	}
}

export default Site;
